export interface Page {
  name: string;
  business_id: string;
  created_at: string;
  custom_fields: any[];
  description: string;
  id: string;
  slug: string;
  status: "ACTIVE" | "INACTIVE";
  updated_at: string;
  dataset?: string;
  domain: "TEST" | "LIVE";
}

export interface PagesState {
  pages: Page[];
}

export enum PagesActions {
  UPDATE_PAGES = "UPDATE_PAGES",
}
