export interface Customer {
  email: string;
  id: string;
  business_id: string;
  last_name: string;
  first_name: string;
  name?: string;
  updated_at: string;
  created_at: string;
}

export interface CustomersState {
  customers: Customer[];
}

export enum CustomersActions {
  GET_ALL_CUSTOMERS_REQUEST_SUCCESS = "GET_ALL_CUSTOMERS_REQUEST_SUCCESS",
  GET_ALL_CUSTOMERS_REQUEST = "GET_ALL_CUSTOMERS_REQUEST",
}
