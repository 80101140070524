import { DatasetActions, DatasetState } from "./types";
import { ActionType } from "../types";

const initialState: DatasetState = {
  datasets: [],
};

const datasetReducer = (
  state: DatasetState = initialState,
  action: ActionType
): DatasetState => {
  switch (action.type) {
    case DatasetActions.UPDATE_DATASET: {
      return {
        ...state,
        datasets: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default datasetReducer;
