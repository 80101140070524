import { CustomersActions, CustomersState } from "./types";
import { ActionType } from "../types";

const initialState: CustomersState = {
  customers: [],
};

const customersReducer = (
  state: CustomersState = initialState,
  action: ActionType
): CustomersState => {
  switch (action.type) {
    case CustomersActions.GET_ALL_CUSTOMERS_REQUEST_SUCCESS: {
      return {
        ...state,
        customers: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default customersReducer;
