import { Bank } from "../../@types/bank";

export interface AccountState {
  isAuthenticated: boolean;
  profile: {
    id: string;
    business_name: string;
    business_email: string;
    business_phone: string;
    is_verified: boolean;
    created_at: string;
  };
  banks: Bank[];
}

export enum AccountActions {
  UPDATE_IS_AUTHENTICATED = "UPDATE_IS_AUTHENTICATED",
  UPDATE_PROFILE = "UPDATE_PROFILE",
  CLEAR_PROFILE = "CLEAR_PROFILE",
  UPDATE_BANKS = "UPDATE_BANKS",
}
