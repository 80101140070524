import React, { lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import PrivateRoutes from "./private-route";
import "./styles/app.css";
import "./styles/auth.style.css";
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/auth/login"));
const CreateAccount = lazy(() => import("./pages/auth/signup"));
const ForgotPassword = lazy(() => import("./pages/auth/reset"));
const ForgotPasswordComplete = lazy(() => import("./pages/auth/reset-confirm"));

function App() {
  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/create-account" component={CreateAccount} />
          <Route exact path="/password/reset" component={ForgotPassword} />
          <Route
            path="/password/reset/:token"
            component={ForgotPasswordComplete}
          />

          <PrivateRoutes>
            <Route path="/" component={Layout} />
          </PrivateRoutes>
        </Switch>
      </Router>
    </>
  );
}

export default App;
