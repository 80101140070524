import { PagesActions, PagesState } from "./types";
import { ActionType } from "../types";

const initialState: PagesState = {
  pages: [],
};

const businessPagesReducer = (
  state: PagesState = initialState,
  action: ActionType
): PagesState => {
  switch (action.type) {
    case PagesActions.UPDATE_PAGES: {
      return {
        ...state,
        pages: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default businessPagesReducer;
