import { AccountActions, AccountState } from "./types";
import { ActionType } from "../types";

const initialState: AccountState = {
  isAuthenticated: false,
  profile: {
    id: "",
    is_verified: false,
    business_email: "",
    business_name: "",
    business_phone: "",
    created_at: "",
  },
  banks: [],
};

const accountReducer = (
  state: AccountState = initialState,
  action: ActionType
): AccountState => {
  switch (action.type) {
    case AccountActions.UPDATE_IS_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    }
    case AccountActions.UPDATE_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      };
    }
    case AccountActions.UPDATE_BANKS: {
      return {
        ...state,
        banks: action.payload,
      };
    }
    case AccountActions.CLEAR_PROFILE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default accountReducer;
