import { GeneralActions, GeneralState } from "./types";
import { ActionType } from "../types";

const initialState: GeneralState = {
  allBanks: [],
};

const generalReducer = (
  state: GeneralState = initialState,
  action: ActionType
): GeneralState => {
  switch (action.type) {
    case GeneralActions.UPDATE_ALL_BANKS: {
      return {
        ...state,
        allBanks: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default generalReducer;
