const theme = {
  colors: {
    primary: "#1e7cf5",
    primary2: "#02073E",
    // 1e7cf5
    secondary: "#F5A623",
    accent: "#a8affc",

    input: {
      text: "#333",
      placeholder: "#bdbdbd",
      // label: "#4f4f4f",
      label: "#212b36",
    },
    border: {
      // input: "#4f4f4f",
      input: "#c4cdd5",
    },
  },
};

export default theme;
