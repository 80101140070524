import { TransactionsActions, TransactionsState } from "./types";
import { ActionType } from "../types";

const initialState: TransactionsState = {
  transactions: [],
};

const transactionsReducer = (
  state: TransactionsState = initialState,
  action: ActionType
): TransactionsState => {
  switch (action.type) {
    case TransactionsActions.GET_ALL_TRANSACTIONS_REQUEST_SUCCESS: {
      return {
        ...state,
        transactions: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default transactionsReducer;
