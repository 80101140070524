export interface Dataset {
  id: string;
  name: string;
  created_at: string;
  data: any;
}

export interface DatasetState {
  datasets: Dataset[];
}

export enum DatasetActions {
  UPDATE_DATASET = "UPDATE_DATASET",
}
