const windmillTheme = {
  button: {
    primary: {
      base: "bg-primary text-white",
      active: "bg-primary text-white",
    },
  },
};

export default windmillTheme;
