import { businessInfoState, BusinessInfoActions } from "./types";
import { ActionType } from "../types";
import { Reducer } from "redux";

const INITIAL_STATE: businessInfoState = {
  BusinessInfo: {
    id: "",
    business_id: "",
    description: "",
    business_category: "",
    business_logo: "",
    state: "",
    address: "",
    website: "",
    is_verified: false,
    created_at: "",
    updated_at: "",
    city: "",
    signature: "",
  },
};

const businessInfoReducer: Reducer<businessInfoState, ActionType> = (
  state: businessInfoState = INITIAL_STATE,
  action: ActionType
): businessInfoState => {
  switch (action.type) {
    case BusinessInfoActions.GET_A_BUSINESS_INFORMATION_SUCCESS: {
      return {
        ...state,
        BusinessInfo: action.payload,
      };
    }

    default:
      return state;
  }
};

export default businessInfoReducer;
