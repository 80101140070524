import { combineReducers } from "redux";
import accountReducer from "./account/reducer";
import generalReducer from "./general/reducer";
import businessPagesReducer from "./pages/reducer";
import customers from "./customers/reducer";
import transactions from "./transactions/reducer";
import businessInfoReducer from "./businessInfo/reducer";
import datasetReducer from "./datasets/reducer";

const appReducer = combineReducers({
  account: accountReducer,
  businessPages: businessPagesReducer,
  general: generalReducer,
  customers,
  transactions,
  business: businessInfoReducer,
  datasets: datasetReducer,
});
// export default appReducer;

const rootReducer = (state: any, action: any) => {
  if (action.type === "CLEAR_ALL_STATE") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
