export interface BusinessInformation {
  id: string;
  business_id: string;
  description: string;
  business_category: string;
  business_logo: string;
  state: string;
  address: string;
  website: string;
  is_verified: boolean;
  created_at: string;
  updated_at: string;
  city: string;
  signature: string;
}

export interface businessInfoState {
  BusinessInfo: BusinessInformation;
}

export enum BusinessInfoActions {
  CREATE_BUSINESS_INFO_SUCCESS = "CREATE_BUSINESS_INFO_SUCCESS",
  GET_A_BUSINESS_INFORMATION_SUCCESS = "GET_A_BUSINESS_INFORMATION_SUCCESS",
}
