export interface Transaction {
  email: string;
  id: string;
  business_id: string;
  purpose: string;
  channel: string;
  status: string;
  amount: number;
  reference: string;
  updated_at: string;
  created_at: string;
}

export interface TransactionsState {
  transactions: Transaction[];
}

export enum TransactionsActions {
  GET_ALL_TRANSACTIONS_REQUEST_SUCCESS = "GET_ALL_TRANSACTIONS_REQUEST_SUCCESS",
  GET_ALL_TRANSACTIONS_REQUEST = "GET_ALL_TRANSACTIONS_REQUEST",
}
